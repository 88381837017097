import React, { useState } from "react";
import volumeKnob from "../../img/Vol_knob.png";
import "./VolumeKnob.css";

const VolumeKnob = (props) => {
  const [volumeValue, setVolumeValue] = useState(100);
  
  const styleInitialize = {
    "--value": 100,
    "--min": 0,
    "--max": 100,
    "--value": 100,
  };

  const handleVolumeChange = (event) => {
    event.target.style.setProperty("--value", event.target.value);
    event.target.style.setProperty(
      "--min",
      event.target.min == "" ? "0" : event.target.min
    );
    event.target.style.setProperty(
      "--max",
      event.target.max == "" ? "100" : event.target.max
    );
    event.target.addEventListener("input", () =>
      event.target.style.setProperty("--value", event.target.value)
    );
    setVolumeValue(event.target.value);

    props.handleVolume(volumeValue)
  };

  return (
    <div className="knob">
      <input
        onChange={handleVolumeChange}
        value={volumeValue}
        type="range"
        className={`styled-slider slider-progress`}
        style={styleInitialize}
      />
    </div>
  );
};

export default VolumeKnob;
