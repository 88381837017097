import React, { useState } from "react";
// import styles from './TrackControl.module.css';
import "./SelectionForm.css";
import SelectionItem from "./SelectionItem";
import InputScreenWrapper from "./InputScreenWrapper";
import BarItems from "./BarItems";
import TimeSignatureItems from './TimeSignatureItems';
import ScaleItems from './ScaleItems';
import musicStyle from "../../img/form/music-style.png";
import musicStyleAct from "../../img/form/music-style-invert.png";
import scale from "../../img/form/scale.png";
import scaleAct from "../../img/form/scale-invert.png";
import tonalNote from "../../img/form/tonal-note.png";
import tonalNoteAct from "../../img/form/tonal-note-invert.png";
import tempo from "../../img/form/tempo.png";
import tempoAct from "../../img/form/tempo-invert.png";
import numberOfBars from "../../img/form/number-of-bars.png";
import numberOfBarsAct from "../../img/form/number-of-bars-invert.png";
import rythmComplexity from "../../img/form/harmonic-complexity.png";
import rythmComplexityAct from "../../img/form/harmonic-complexity-invert.png";
import noteDensity from "../../img/form/note-density.png";
import noteDensityAct from "../../img/form/note-density-invert.png";
import emotion from "../../img/form/emotion.png";
import emotionAct from "../../img/form/emotion-invert.png";
import sad from "../../img/form/sad.png";
import neutral from "../../img/form/neutral.png";
import timeSignature from "../../img/form/time-signature.png";
import timeSignatureAct from "../../img/form/time-signature-invert.png";

import calmness from "../../img/form/calmness.png";
import intensity from "../../img/form/intensity.png";

import complexityBasic from "../../img/form/complexity-basic.png";
import complexityComplex from "../../img/form/complexity-complex.png";

const SelectionForm = (props) => {
  const [currentMode, setCurrentMode] = useState("initial");
  const [tempoValue, setTempoValue] = useState(120);
  const [tempoIsSelected, setTempoIsSelected] = useState(false);
  const [noteDensityValue, setNoteDensityValue] = useState(2);
  const [noteDensityIsSelected, setNoteDensityIsSelected] = useState(false);
  const [rythmComplexityValue, setRythmComplexityValue] = useState(1);
  const [tensionValue, setTensionValue] = useState(2);
  const [tensionIsSelected, setTensionIsSelected] = useState(false);
  const [scaleValue, setScaleValue] = useState("minor");
  const [scaleIsSelected, setScaleIsSelected] = useState(false);
  const [timeSignatureValue, setTimeSignatureValue] = useState("4/4");
  const [timeSignatureIsSelected, setTimeSignatureIsSelected] = useState(false);
  const [rythmComplexityIsSelected, setRythmComplexityIsSelected] =
    useState(false);
  const [numberOfBarsValue, setNumberOfBarsValue] = useState("21-24");
  const [numberOfBarsValueIsSelected, setNumberOfBarsIsSelected] =
    useState(false);

  const changeInputScreenHandler = (value) => {
    setCurrentMode(value.length > 0 ? value : "initial");
  };

  const parametersCompletedHandler = () => {
    let parameters ={
      scale:scaleValue,
      timesig:timeSignatureValue,
      numOfBars:numberOfBarsValue,
      tension:tensionValue,
      rhythm_complexity:rythmComplexityValue,
      density:noteDensityValue,
      tempo:tempoValue,
    };
    props.onParametersCompleted(parameters);
  };

  const handleTempoChange = (event) => {
    setTempoValue(event.target.value);
    setTempoIsSelected(true);
  };

  const handleNoteDensityChange = (event) => {
    setNoteDensityValue(event.target.value);
    setNoteDensityIsSelected(true);
  };

  const handleHarmonicComplexityChange = (event) => {
    setRythmComplexityValue(event.target.value);
    setRythmComplexityIsSelected(true);
  };

  const handleNumberOfBarsChange = (value) => {
    setNumberOfBarsValue(value);
    setNumberOfBarsIsSelected(true);
  };

  const handleTensionChange = (event) => {
    setTensionValue(event.target.value);
    setTensionIsSelected(true);
  };

  const handleScaleChange = (value) => {
    setScaleValue(value);
    setScaleIsSelected(true);
  };

  const handleTimeSignatureChange = (value) => {
    setTimeSignatureValue(value);
    setTimeSignatureIsSelected(true);
  };

  return (
    <div
      className={`selection-form-wrapper ${
        props.status === "newtrack" ? "show" : ""
      }`}
    >
      <h1>Select track parameters</h1>
      <div className="form-elements-wrapper">
        <div className="col-25">
          <div className="form-category-label">Music Style</div>
          <SelectionItem
            controlDisabled={true}
            itemImage={musicStyle}
            itemImageAct={musicStyleAct}
            title="Style"
          />
        </div>
        <div className="col-50">
          <div className="form-category-label">Track Options</div>
          <div className="middle-column-wrapper">
            <SelectionItem
              type="tempo"
              itemImage={tempo}
              onInitiateInput={changeInputScreenHandler}
              itemImageAct={tempoAct}
              title="Tempo"
              valueSelected={tempoValue}
              valueIsSelected={tempoIsSelected}
            />
            <SelectionItem
              itemImage={scale}
              itemImageAct={scaleAct}
              type="scale"
              title="Scale"
              onInitiateInput={changeInputScreenHandler}
              valueSelected={scaleValue}
              valueIsSelected={scaleIsSelected}
            />
            <SelectionItem
              type="numberOfBars"
              itemImage={numberOfBars}
              onInitiateInput={changeInputScreenHandler}
              itemImageAct={numberOfBarsAct}
              title="Number of Bars"
              valueSelected={numberOfBarsValue}
              valueIsSelected={numberOfBarsValueIsSelected}
            />
            <SelectionItem
              itemImage={tonalNote}
              controlDisabled={true}
              itemImageAct={tonalNoteAct}
              title="Tonal Note"
            />
            <SelectionItem
              itemImage={timeSignature}
              itemImageAct={timeSignatureAct}
              title="Time Signature"
              type="timeSignature"
              onInitiateInput={changeInputScreenHandler}
              valueSelected={timeSignatureValue}
              valueIsSelected={timeSignatureIsSelected}
            />
          </div>
        </div>
        <div className="col-25">
          <div className="form-category-label">Complexity</div>
          <div className="right-column-wrapper">
            <SelectionItem
              type="rythmComplexity"
              onInitiateInput={changeInputScreenHandler}
              itemImage={rythmComplexity}
              itemImageAct={rythmComplexityAct}
              title="Rythm Complexity"
              valueSelected={rythmComplexityValue}
              valueIsSelected={rythmComplexityIsSelected}
            />
            <SelectionItem
              type="noteDensity"
              onInitiateInput={changeInputScreenHandler}
              itemImage={noteDensity}
              itemImageAct={noteDensityAct}
              title="Note Density"
              valueSelected={noteDensityValue}
              valueIsSelected={noteDensityIsSelected}
            />
            <SelectionItem
              type="tension"
              onInitiateInput={changeInputScreenHandler}
              itemImage={emotion}
              itemImageAct={emotionAct}
              title="Emotion"
              valueSelected={tensionValue}
              valueIsSelected={tensionIsSelected}
            />
          </div>
        </div>
      </div>
      <button className="btn mt-3" onClick={parametersCompletedHandler}>
        GENERATE TRACK
      </button>

      <InputScreenWrapper
        title={"Select Track Tempo"}
        forScreen={"tempo"}
        changeInputScreenHandler={changeInputScreenHandler}
        currentMode={currentMode}
        text={
          "Every music piece has a tempo, which is expressed in beats per minute (bpm). Tempo is the speed or pace of a given piece. For example, a tempo of 60 beats per minute signifies one beat per second, while a tempo of 120 beats per minute is twice as rapid, signifying two beats every second."
        }
      >
        <div>
          <div className="input-value mb-2">{tempoValue}</div>
          <div className="slider-wrapper">
            <input
              value={tempoValue}
              onChange={handleTempoChange}
              type="range"
              min="50"
              max="200"
              step="1"
            />
          </div>
        </div>
      </InputScreenWrapper>

      <InputScreenWrapper
        title={"Select Track Density"}
        forScreen={"noteDensity"}
        changeInputScreenHandler={changeInputScreenHandler}
        currentMode={currentMode}
        text={
          "Average number of notes per second, taking local tempo into account.The density in a piece of music with only a few instruments playing,could be described as light, thin, or even sparse. The density of a large group of instrument playing could be heavy, dense, think or even compact."
        }
      >
        <div>
          <div className="input-value d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={calmness} width="72" height="58" />
              <div className="note-density-label">Calmness</div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={intensity} width="129" height="58" />
              <div className="note-density-label">Intensity</div>
            </div>
          </div>
          <div className="slider-wrapper">
            <input
              value={noteDensityValue}
              onChange={handleNoteDensityChange}
              type="range"
              min="0"
              max="4"
              step="1"
            />
          </div>
        </div>
      </InputScreenWrapper>

      <InputScreenWrapper
        title={"Select Rythm Complexity"}
        forScreen={"rythmComplexity"}
        changeInputScreenHandler={changeInputScreenHandler}
        currentMode={currentMode}
        text={
          "  In music, relative keys are the major and minor scales that have the same key signatures (enharmonically equivalent), meaning that they share all the same notes but are arranged in a different order of whole steps and half steps."
        }
      >
        <div>
          <div className="input-value d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={complexityBasic} width="83" height="83" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={complexityComplex} width="83" height="83" />
            </div>
          </div>
          <div className="slider-wrapper">
            <input
              value={rythmComplexityValue}
              onChange={handleHarmonicComplexityChange}
              type="range"
              min="0"
              max="2"
              step="1"
            />
          </div>
        </div>
      </InputScreenWrapper>

      <InputScreenWrapper
        title={"Select number of bars"}
        forScreen={"numberOfBars"}
        changeInputScreenHandler={changeInputScreenHandler}
        currentMode={currentMode}

        text={
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed deiusmod tempor incididunt ut labore et eston dolore magna aliqua. Utenim ad minim veniam, quis nostrud exercitation ullamco laboris nisiut aliquip ex ea commodo consequat."
        }
      >
        <BarItems onNumberOfBarsSet = {handleNumberOfBarsChange}/>
      </InputScreenWrapper>

      <InputScreenWrapper
        title={"Select track emotion"}
        forScreen={"tension"}
        changeInputScreenHandler={changeInputScreenHandler}
        currentMode={currentMode}
        text={
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed deiusmod tempor incididunt ut labore et eston dolore magna aliqua. Utenim ad minim veniam, quis nostrud exercitation ullamco laboris nisiut aliquip ex ea commodo consequat."
        }
      >
        <div>
          <div className="input-value d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={sad} width="100" height="100" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={neutral} width="100" height="100" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={emotion} width="100" height="100" />
            </div>
          </div>
          <div className="slider-wrapper">
            <input
              value={tensionValue}
              onChange={handleTensionChange}
              type="range"
              min="0"
              max="4"
              step="1"
            />
          </div>
          <div className="slider-text-value">
            {tensionValue < 2 ? "Sad" : tensionValue == 2 ? "Neutral" : "Happy"}
          </div>
        </div>
      </InputScreenWrapper>

      <InputScreenWrapper
        title={"Select track scale"}
        forScreen={"scale"}
        changeInputScreenHandler={changeInputScreenHandler}
        currentMode={currentMode}
        text={
          "In music, relative keys are the major and minor scales that have the same key signatures (enharmonically equivalent), meaning that they share all the same notes but are arranged in a different order of whole steps and half steps."
        }
      >
       <ScaleItems  onValueSelected={handleScaleChange} currentValue={scaleValue} />
      </InputScreenWrapper>

      <InputScreenWrapper
        title={"Select time signature"}
        forScreen={"timeSignature"}
        changeInputScreenHandler={changeInputScreenHandler}
        currentMode={currentMode}
        text={
          "In music, relative keys are the major and minor scales that have the same key signatures (enharmonically equivalent), meaning that they share all the same notes but are arranged in a different order of whole steps and half steps."
        }
      >
        <TimeSignatureItems onValueSelected={handleTimeSignatureChange} currentValue={timeSignatureValue}/>
     
      </InputScreenWrapper>
    </div>
  );
};

export default SelectionForm;
