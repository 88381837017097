import React, { useState } from "react";
import "./TimeSignatureItems.css";

const TimeSignatureItems = (props) => {
  const [isSelected, setIsSelected] = useState(false);

  const textItemClickHandler = (event) => {
    props.onValueSelected(event.target.innerHTML);
    setIsSelected(!isSelected);
  };

  return (
    <div className="input-value d-flex justify-content-between align-items-center mb-2">
      <div
        className={`text-selection-item ${
          props.currentValue == "3/4" ? "text-selection-active" : ""
        }`}
        onClick={textItemClickHandler}
      >
        3/4
      </div>
      <div
        className={`text-selection-item ${
          props.currentValue == "4/4" ? "text-selection-active" : ""
        }`}
        onClick={textItemClickHandler}
      >
        4/4
      </div>
      <div
        className={`text-selection-item ${
          props.currentValue == "6/4" ? "text-selection-active" : ""
        }`}
        onClick={textItemClickHandler}
      >
        6/4
      </div>
    </div>
  );
};

export default TimeSignatureItems;
