import React,{useState} from "react";
import "./QuickSelectionArea.css";




const QuickSelectionForm = (props) => {
    const [tempoValue, setTempoValue] = useState(120);
    const [noteDensityValue, setNoteDensityValue] = useState(2);
    const [rythmComplexityValue, setRythmComplexityValue] = useState(1);
    const [tensionValue, setTensionValue] = useState(2);
    const [scaleValue, setScaleValue] = useState("minor");
    const [timeSignatureValue, setTimeSignatureValue] = useState("4/4");
    const [numberOfBarsValue, setNumberOfBarsValue] = useState("21-24");

    const quickGenerateHandler = () => {
        let parameters = {
            scale:scaleValue,
            timesig:timeSignatureValue,
            numOfBars:numberOfBarsValue,
            tension:tensionValue,
            rhythm_complexity:rythmComplexityValue,
            density:noteDensityValue,
            tempo:tempoValue,
        }
        props.quickGenerateHandler(parameters);
    };

    const handleScaleChange = (event) =>{
        setScaleValue(event.target.value);
    }

    const handleTimeSingatureChange = (event) =>{
        setTimeSignatureValue(event.target.value);
    }

    const handleTempoChange = (event) =>{
        setTempoValue(event.target.value);
    }

    const handleRythmComplexityChange = (event) =>{
        setRythmComplexityValue(event.target.value);
    }

    const handleNoteDensityChange = (event) =>{
        setNoteDensityValue(event.target.value);
    }

    const handleTensionChange = (event) =>{
        setTensionValue(event.target.value);
    }

    const handleNumberOfBarsChange = (event)=>{
        setNumberOfBarsValue(event.target.value);
    }

    // const mapMidiSpecs = (props.currentMidi.map((midiData) => {
    
    //   return {
    //     scale: midiData.scale,
    //     timesig: midiData.timesig,
    //     numbOfBars: midiData.numOfBars,
    //     tension:midiData.tension,
    //     rhythm_complexity:midiData.rhythm_complexity,
    //     density:midiData.density,
    //     tempo:midiData.tempo
    //   };
    // }));

  

  return (
    <div className={`quick-selection-area ${props.quickSelectionGenRunning?'quick-gen-running':''}`}>
      {/* {props.currentMidi &&
      <div className="midi-specs-display">
        <div className="specs-item">
          <span className="specs-label">Filename:</span> 120BPM
        </div>
        <div className="specs-item">
          <span className="specs-label">Scale:</span> 120BPM
        </div>
        <div className="specs-item">
          <span className="specs-label">Tempo:</span> 120BPM
        </div>
        <div className="specs-item">
          <span className="specs-label">Rhythm Complexity:</span> 120BPM
        </div>
        <div className="specs-item">
          <span className="specs-label">Number of Bars:</span> 120BPM
        </div>
        <div className="specs-item">
          <span className="specs-label">Density:</span> 120BPM
        </div>
        <div className="specs-item">
          <span className="specs-label">Time Signature:</span> 120BPM
        </div>
        <div className="specs-item">
          <span className="specs-label">Emotion:</span> 120BPM
        </div>
      </div>} */}
      <div className={`quick-selection-form ${props.quickSelectionActive?'quick-selection-active':''}`}>
        <div className="quick-selection-item">
          <label>Scale: <span className="label-int">{scaleValue}</span></label>
          <select value={scaleValue} onChange={handleScaleChange}>
            <option value="major">Major</option>
            <option value="minor">Minor</option>
          </select>
          <span className="focus"></span>
        </div>
        <div className="quick-selection-item">
          <label>Tempo: <span className="label-int">{tempoValue}</span></label>
          <input value={tempoValue} onChange={handleTempoChange} type="range" min="50" max="200" step="1" />
        </div>
        <div className="quick-selection-item">
          <label>Rhythm Complexity: <span className="label-int">{rythmComplexityValue}</span></label>
          <input value={rythmComplexityValue} onChange={handleRythmComplexityChange} type="range" min="0" max="2" step="1" />
        </div>
        <div className="quick-selection-item">
          <label>Number of Bars: <span className="label-int">{numberOfBarsValue}</span></label>
          <select value={numberOfBarsValue} onChange={handleNumberOfBarsChange}>
          <option value="4-8">4-8</option>
          <option value="9-12">9-12</option>
          <option value="13-16">13-16</option>
          <option value="17-20">17-20</option>
          <option value="21-24">21-24</option>
          <option value="25-28">25-28</option>
          <option value="29-32">29-32</option>
          </select>
          <span className="focus"></span>
        </div>
        <div className="quick-selection-item">
          <label>Density: <span className="label-int">{noteDensityValue}</span></label>
          <input value={noteDensityValue} onChange={handleNoteDensityChange} type="range" min="0" max="4" step="1" />
        </div>
        <div className="quick-selection-item">
          <label>Time Signature: <span className="label-int">{timeSignatureValue}</span></label>
          <select value={timeSignatureValue} onChange={handleTimeSingatureChange}>
            <option value="3/4">3/4</option>
            <option value="4/4">4/4</option>
            <option value="6/4">6/4</option>
          </select>
          <span className="focus"></span>
        </div>
        <div className="quick-selection-item">
          <label>Emotion: <span className="label-int">{tensionValue}</span></label>
          <input value={tensionValue} onChange={handleTensionChange} type="range" min="0" max="4" step="1" />
        </div>
        <div className="quick-selection-button-wrapper">
            <div className="lds-dual-ring"></div>
            <button className="btn" onClick={quickGenerateHandler}>GENERATE</button>
        </div>
        
      </div>
    </div>
  );
};

export default QuickSelectionForm;
