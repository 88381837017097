import React, { useState } from "react";
import BarItem from './BarItem';


const BarItems = (props) => {

const [barsValue, setBarsValue] = useState(0);

const barItemClickHandler = (value,index) => {
    setBarsValue(index);
    props.onNumberOfBarsSet(value);
}


  return ( 
    <div className="input-value d-flex flex-column align-items-center mb-2">
    <div className="d-flex  justify-content-center align-items-center bar-items-wrapper">
      <BarItem type="first" barClicked={barItemClickHandler}  active={barsValue>=1?true:false} index={1} barsLabel="4-8" />
      <BarItem type="normal" barClicked={barItemClickHandler} active={barsValue>=2?true:false} index={2} barsLabel="9-12" />
      <BarItem type="normal" barClicked={barItemClickHandler} active={barsValue>=3?true:false} index={3}  barsLabel="13-16" />
      <BarItem type="normal" barClicked={barItemClickHandler}  active={barsValue>=4?true:false} index={4} barsLabel="17-20" />
      <BarItem type="normal" barClicked={barItemClickHandler}  active={barsValue>=5?true:false} index={5} barsLabel="21-24" />
      <BarItem type="normal" barClicked={barItemClickHandler}  active={barsValue>=6?true:false} index={6} barsLabel="25-28" />
      <BarItem type="normal" barClicked={barItemClickHandler}  active={barsValue>=7?true:false} index={7} barsLabel="29-32" />
      
    </div>
    {/* <div className="d-flex justify-content-center align-items-center bar-items-wrapper">
      <BarItem type="first" barClicked={barItemClickHandler} active={barsValue>=9?true:false} index={9}  barsLabel="16-18" />
      <BarItem type="normal" barClicked={barItemClickHandler} active={barsValue>=10?true:false} index={10}  barsLabel="18-20" />
      <BarItem type="normal" barClicked={barItemClickHandler} active={barsValue>=11?true:false} index={11}  barsLabel="20-22" />
      <BarItem type="normal" barClicked={barItemClickHandler} active={barsValue>=12?true:false} index={12}  barsLabel="22-24" />
      <BarItem type="normal" barClicked={barItemClickHandler} active={barsValue>=13?true:false} index={13}  barsLabel="24-26" />
      <BarItem type="normal" barClicked={barItemClickHandler} active={barsValue>=14?true:false} index={14}  barsLabel="26-28" />
      <BarItem type="normal" barClicked={barItemClickHandler} active={barsValue>=15?true:false} index={15}  barsLabel="28-30" />
      <BarItem type="normal" barClicked={barItemClickHandler} active={barsValue>=16?true:false} index={16}  barsLabel="30-32" />
    </div> */}
  </div>
  )

}
export default BarItems;
