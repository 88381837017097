import React, { ReactFragment } from "react";
import './PendingItem.css';
import Loader from '../UI/Loader';
const PendingItem = (props) => {
  return (
    <React.Fragment>
      <div className="table-row">
        <div className="title-cell">
          <div className="cell-image-wrapper">
            <Loader/>
            </div>
          Generating
        </div>
      </div>
    </React.Fragment>
  );
};

export default PendingItem;
