import React,{useState} from 'react';
// import styles from './TrackControl.module.css';
import './TrackControl.css';
import VolumeKnob from './VolumeKnob';
import piano from '../../img/icon_piano.png';
import cello from '../../img/cello.png'
import guitar from '../../img/icon_guitar.png';
import mute from '../../img/icons8-mute-100.png';
import audio from '../../img/icons8-audio-100.png';
import drums from '../../img/drums.png';
import solo from '../../img/Solo.png';
import soloActive from '../../img/Solo-active.png';
import settings from '../../img/slider-100.png';

const TrackControl = (props) =>{

    const [soundState,setSoundState] = useState(true);
    const [soloState, setSoloState] = useState(false);

    const handleMute = () => {
        setSoundState(!soundState);
        props.onChangeMute(soundState,props.instrument);
    }

    const handleSolo = () => {
        setSoloState(!soloState);
        props.onChangeSolo(props.instrument,soloState);
    }

    const handleVolumeKnobChange = (value) =>{
       props.onChangeVolume(value,props.instrument);
    }

    return (<div className="track-control">
        <div className="barleft"></div>
        <div className="details-wrapper">
             <img className="instrument-image" src={props.instrument=="Guitar"?guitar:props.instrument=="Vocals"?piano:props.instrument=="Drums"?drums:cello}/>
             <div className="name-controls" >
                 <div className="track-title">{props.instrument}</div>
                 <div className='controls-wrapper'>
                        <div><img src={soloState?soloActive:solo} onClick={handleSolo}/></div>
                        <div><img src={soundState ? audio:mute} onClick={handleMute}/></div>
                        <div><img src={settings}/></div>
                 </div>
             </div>
        </div>
        <VolumeKnob handleVolume={handleVolumeKnobChange} volume={props.volume} />
    </div>);
}

export default TrackControl;