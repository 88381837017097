import * as  Tone from "tone";

const Samples = {
    baseUrl: '../samples/',
    ext: '.[mp3]',
    list:  [
        "piano",
        "bass-electric",
        "guitar-electric",
        "harmonium",
        "violin",
        "cello",
        "kick",
        "snare",
        "snareStick",
        "tom1",
        "tom2",
        "tom3",
        "hihat",
        "hihatOpen",
        "crash1",
        "crash2",
        "ride",
        "china",
      ],
    setExt: function (newExt) {
        var i
        for (i = 0; i <= this.list.length - 1; i++) {
            for (var property in this[this.list[i]]) {

                this[this.list[i]][property] = this[this.list[i]][property].replace(this.ext, newExt)
            }


        }
        this.ext = newExt;
        return console.log("sample extensions set to " + this.ext)
    },

    load: function (arg) {
        var t, rt, i;
        (arg) ? t = arg: t = {};
        t.instruments = t.instruments || this.list;
        t.baseUrl = t.baseUrl || this.baseUrl;
        t.onload = t.onload || this.onload;

        // update extensions if arg given
        if (t.ext) {
            if (t.ext != this.ext) {
                this.setExt(t.ext)
            }
            t.ext = this.ext
        }

        rt = {};

        // if an array of instruments is passed...
        if (Array.isArray(t.instruments)) {
            for (i = 0; i <= t.instruments.length - 1; i++) {
                var newT = this[t.instruments[i]];
                //Minimize the number of samples to load
                if (this.minify === true || t.minify === true) {
                    var minBy = 1;
                    if (Object.keys(newT).length >= 17) {
                        minBy = 2
                    }
                    if (Object.keys(newT).length >= 33) {
                        minBy = 4
                    }
                    if (Object.keys(newT).length >= 49) {
                        minBy = 6
                    }

                    var filtered = Object.keys(newT).filter(function (_, i) {
                        return i % minBy != 0;
                    })
                    filtered.forEach(function (f) {
                        delete newT[f]
                    })

                }




                rt[t.instruments[i]] = new Tone.Sampler(
                    newT, {
                        baseUrl: t.baseUrl + t.instruments[i] + "/",
                        onload: t.onload
                    }

                )
            }

            return rt

            // if a single instrument name is passed...
        } else {
            newT = this[t.instruments];

            //Minimize the number of samples to load
            if (this.minify === true || t.minify === true) {
                minBy = 1;
                if (Object.keys(newT).length >= 17) {
                    minBy = 2
                }
                if (Object.keys(newT).length >= 33) {
                    minBy = 4
                }
                if (Object.keys(newT).length >= 49) {
                    minBy = 6
                }

                filtered = Object.keys(newT).filter(function (_, i) {
                    return i % minBy != 0;
                })
                filtered.forEach(function (f) {
                    delete newT[f]
                })
            }




            var s = new Tone.Sampler(
                newT, {
                    baseUrl: t.baseUrl + t.instruments + "/",
                    onload: t.onload
                }
            )

            return s
        }

    },

    'bass-electric': {
        'A#3': 'As3.[mp3|ogg]',
        'C4': 'C4.[mp3|ogg]',
        'E2': 'E2.[mp3|ogg]',
        'G#1': 'Gs1.[mp3|ogg]',
    },
    'harmonium': {
        'C2': 'C2.[mp3|ogg]',
        'C3': 'C3.[mp3|ogg]',
        'C4': 'C4.[mp3|ogg]',
        'C5': 'C5.[mp3|ogg]',
        'C#2': 'Cs2.[mp3|ogg]',
        'C#3': 'Cs3.[mp3|ogg]',
        'C#4': 'Cs4.[mp3|ogg]',
        'C#5': 'Cs5.[mp3|ogg]',
        'D2': 'D2.[mp3|ogg]',
        'D3': 'D3.[mp3|ogg]',
        'D4': 'D4.[mp3|ogg]',
        'D5': 'D5.[mp3|ogg]',
        'D#2': 'Ds2.[mp3|ogg]',
        'D#3': 'Ds3.[mp3|ogg]',
        'D#4': 'Ds4.[mp3|ogg]',
        'E2': 'E2.[mp3|ogg]',
        'E3': 'E3.[mp3|ogg]',
        'E4': 'E4.[mp3|ogg]',
        'F2': 'F2.[mp3|ogg]',
        'F3': 'F3.[mp3|ogg]',
        'F4': 'F4.[mp3|ogg]',
        'F#2': 'Fs2.[mp3|ogg]',
        'F#3': 'Fs3.[mp3|ogg]',
        'G2': 'G2.[mp3|ogg]',
        'G3': 'G3.[mp3|ogg]',
        'G4': 'G4.[mp3|ogg]',
        'G#2': 'Gs2.[mp3|ogg]',
        'G#3': 'Gs3.[mp3|ogg]',
        'G#4': 'Gs4.[mp3|ogg]',
        'A2': 'A2.[mp3|ogg]',
        'A3': 'A3.[mp3|ogg]',
        'A4': 'A4.[mp3|ogg]',
        'A#2': 'As2.[mp3|ogg]',
        'A#3': 'As3.[mp3|ogg]',
        'A#4': 'As4.[mp3|ogg]'
    },

    'cello': {
        'E3': 'E3.[mp3|ogg]',
        'F2': 'F2.[mp3|ogg]',
        'G2': 'G2.[mp3|ogg]',
        'A2': 'A2.[mp3|ogg]',
        'B2': 'B2.[mp3|ogg]',
        'B3': 'B3.[mp3|ogg]',
        'B4': 'B4.[mp3|ogg]',
        'C2': 'C2.[mp3|ogg]',
        'D2': 'D2.[mp3|ogg]',
        'E2': 'E2.[mp3|ogg]'

    },  
    'guitar-electric': {
        'A2': 'A2.mp3',
        'A3': 'A3.mp3',
        'A4': 'A4.mp3',
        'A5': 'A5.mp3',
        'C#3': 'Cs3.mp3',
        'C#4': 'Cs4.mp3',
        'C#5': 'Cs5.mp3',
        'C6': 'C6.mp3',
        'E2': 'E2.mp3',
        'F3': 'F3.mp3',
        'F4': 'F4.mp3',
        'F5': 'F5.mp3'
    },



    'guitar-acoustic': {
        'D#3': 'Ds3.[mp3|ogg]',
        'D#4': 'Ds4.[mp3|ogg]',
        'E2': 'E2.[mp3|ogg]',
    },

    'piano': {
        A3: "A3.mp3",
        A4: "A4.mp3",
        A5: "A5.mp3",
        A6: "A6.mp3",
        A7: "A7.mp3",
        C1: "C1.mp3",
        C4: "C4.mp3",
        C7: "C7.mp3"
    },



    'violin': {
        'A3': 'A3.[mp3|ogg]',
        'C4': 'C4.[mp3|ogg]', 
        'E4': 'E4.[mp3|ogg]',
        'G6': 'G6.[mp3|ogg]'
    },

    'kick': {
    	'C1': 'kick.mp3',
        'B0': 'kick.mp3'
    },

    'snare': {
    	'D1': 'snare.mp3',
        'E1': 'snare.mp3',
    },

    'snareStick': {
        'C#1': 'snareStick.mp3',
        'D#1': 'snareStick.mp3'
    },

    'hihat': {
    	'F#1': 'ClosedHiHat.mp3',
        'G#1': 'ClosedHiHat.mp3',
        'A#1': 'ClosedHiHat.mp3',
    },

    'hihatOpen':{
            'F#1': 'PedalHiHat.mp3',
            'G#1': 'PedalHiHat.mp3',
            'A#1': 'PedalHiHat.mp3',
    },

    'ride': {
        'D#2': 'ride.mp3',
        'F2': 'ride.mp3',
        'G#2': 'ride.mp3',
        'B2': 'ride.mp3'
    },

    'crash1': {
        'C#2': 'crash1.mp3'
    },

    'crash2': {
        'A2': 'crash2.mp3',
    },

    'china': {
        'E2': 'china.mp3',
        'G2': 'china.mp3'
    },

    'tom1': {
        'C2': 'tom1.mp3',
        'D2': 'tom1.mp3',
    },
    'tom2':{
        'A1': 'tom2.mp3',
        'B1': 'tom2.mp3',
    },
    'tom3':{
        'F1': 'tom3.mp3',
        'G1': 'tom3.mp3'
    },
    'china':{
        'E2':'china.mp3'
    }
};
// 41 F1 Low Floor Tom
// 47 B1 Low-Mid Tom
// 43 G1 High Floor Tom

// 45 A1 Low Tom
// 47 B1 Low-Mid Tom
// 48 C2 Hi Mid Tom
// 50 D2 High Tom
export default Samples;

// 'A0': 'A0.[mp3|ogg]',
// 'A1': 'A1.[mp3|ogg]',
// 'A2': 'A2.[mp3|ogg]',
// 'A3': 'A3.[mp3|ogg]',
// 'A4': 'A4.[mp3|ogg]',
// 'A5': 'A5.[mp3|ogg]',
// 'A6': 'A6.[mp3|ogg]',
// 'A#0': 'As0.[mp3|ogg]',
// 'A#1': 'As1.[mp3|ogg]',
// 'A#2': 'As2.[mp3|ogg]',
// 'A#3': 'As3.[mp3|ogg]',
// 'A#4': 'As4.[mp3|ogg]',
// 'A#5': 'As5.[mp3|ogg]',
// 'A#6': 'As6.[mp3|ogg]',
// 'B0': 'B0.[mp3|ogg]',
// 'B1': 'B1.[mp3|ogg]',
// 'B2': 'B2.[mp3|ogg]',
// 'B3': 'B3.[mp3|ogg]',
// 'B4': 'B4.[mp3|ogg]',
// 'B5': 'B5.[mp3|ogg]',
// 'B6': 'B6.[mp3|ogg]',
// 'C0': 'C0.[mp3|ogg]',
// 'C1': 'C1.[mp3|ogg]',
// 'C2': 'C2.[mp3|ogg]',
// 'C3': 'C3.[mp3|ogg]',
// 'C4': 'C4.[mp3|ogg]',
// 'C5': 'C5.[mp3|ogg]',
// 'C6': 'C6.[mp3|ogg]',
// 'C7': 'C7.[mp3|ogg]',
// 'C#0': 'Cs0.[mp3|ogg]',
// 'C#1': 'Cs1.[mp3|ogg]',
// 'C#2': 'Cs2.[mp3|ogg]',
// 'C#3': 'Cs3.[mp3|ogg]',
// 'C#4': 'Cs4.[mp3|ogg]',
// 'C#5': 'Cs5.[mp3|ogg]',
// 'C#6': 'Cs6.[mp3|ogg]',
// 'D0': 'D0.[mp3|ogg]',
// 'D1': 'D1.[mp3|ogg]',
// 'D2': 'D2.[mp3|ogg]',
// 'D3': 'D3.[mp3|ogg]',
// 'D4': 'D4.[mp3|ogg]',
// 'D5': 'D5.[mp3|ogg]',
// 'D6': 'D6.[mp3|ogg]',
// 'D#0': 'Ds0.[mp3|ogg]',
// 'D#1': 'Ds1.[mp3|ogg]',
// 'D#2': 'Ds2.[mp3|ogg]',
// 'D#3': 'Ds3.[mp3|ogg]',
// 'D#4': 'Ds4.[mp3|ogg]',
// 'D#5': 'Ds5.[mp3|ogg]',
// 'D#6': 'Ds6.[mp3|ogg]',
// 'E0': 'E0.[mp3|ogg]',
// 'E1': 'E1.[mp3|ogg]',
// 'E2': 'E2.[mp3|ogg]',
// 'E3': 'E3.[mp3|ogg]',
// 'E4': 'E4.[mp3|ogg]',
// 'E5': 'E5.[mp3|ogg]',
// 'E6': 'E6.[mp3|ogg]',
// 'F0': 'F0.[mp3|ogg]',
// 'F1': 'F1.[mp3|ogg]',
// 'F2': 'F2.[mp3|ogg]',
// 'F3': 'F3.[mp3|ogg]',
// 'F4': 'F4.[mp3|ogg]',
// 'F5': 'F5.[mp3|ogg]',
// 'F6': 'F6.[mp3|ogg]',
// 'F#0': 'Fs0.[mp3|ogg]',
// 'F#1': 'Fs1.[mp3|ogg]',
// 'F#2': 'Fs2.[mp3|ogg]',
// 'F#3': 'Fs3.[mp3|ogg]',
// 'F#4': 'Fs4.[mp3|ogg]',
// 'F#5': 'Fs5.[mp3|ogg]',
// 'F#6': 'Fs6.[mp3|ogg]',
// 'G0': 'G0.[mp3|ogg]',
// 'G1': 'G1.[mp3|ogg]',
// 'G2': 'G2.[mp3|ogg]',
// 'G3': 'G3.[mp3|ogg]',
// 'G4': 'G4.[mp3|ogg]',
// 'G5': 'G5.[mp3|ogg]',
// 'G6': 'G6.[mp3|ogg]',
// 'G#0': 'Gs0.[mp3|ogg]',
// 'G#1': 'Gs1.[mp3|ogg]',
// 'G#2': 'Gs2.[mp3|ogg]',
// 'G#3': 'Gs3.[mp3|ogg]',
// 'G#4': 'Gs4.[mp3|ogg]',
// 'G#5': 'Gs5.[mp3|ogg]',
// 'G#6': 'Gs6.[mp3|ogg]'