import React, { useState } from "react";
import playIcon from '../../img/playback/play.png';
import playFilled from '../../img/playback/play-filled.png';
import rewind from '../../img/playback/rewind.png';
import rewindFilled from '../../img/playback/rewind-filled.png';
import skip from '../../img/playback/skip.png';
import skipFilled from '../../img/playback/skip-filled.png';

import "./PlaybackControls.css";

const PlaybackControls = (props) => {
  const [play,setPlay] = useState(false);

  const startPlayBack = () =>{
    props.onChangePlayBack();
    setPlay(play?false:true);
  }

  const skipForward = () =>{
    props.onSkipForward();
  }

  const skipBackward = () =>{
    props.onSkipBackward();
  }

  const fastForward = () =>{
    props.onFastForward();
  }

  return (
   <div className="d-flex justify-content-center">
        <div className="playback-controls">
            <a className="button" onClick={skipBackward}><img src={skip} width="27" height="27"/></a>
            <a className="button"><img src={rewind} width="27" height="27"/></a>
            <a className="button play" onClick={startPlayBack}><img src={play?playFilled:playIcon} width="29" height="29"/></a>
            <a className="button invert" onClick={fastForward}><img src={rewind} width="27" height="27"/></a>
            <a className="button invert" onClick={skipForward}><img src={skip} width="27" height="27"/></a>
        </div>
        </div>
  );
};

export default PlaybackControls;
