import React, { useState } from "react";
import "./BarItem.css";


const BarItem = (props) => {
const [isSelected, setIsSelected] = useState(false);
const svgReturn = props.type;

const barItemClickHandler = () => {
    setIsSelected(!isSelected);
    props.barClicked(props.barsLabel, props.index);
}


  return ( 
    <div  className="bar-item" onClick={barItemClickHandler}> <div className="bar-item-label">{props.barsLabel}</div>
  {svgReturn=="normal"? (
  <svg xmlns="http://www.w3.org/2000/svg" className={(props.active)?'bar-item-active':'bar-item-normal'} width="99" height="55" viewBox="0 0 99 55">
  <g id="Group_20" transform="translate(-552.5 -557.5)">
	<path id="Path_732"  d="M555,559h94"/>
	<path id="Path_732_-_Outline"  d="M649,560.5h-94c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h94c0.8,0,1.5,0.7,1.5,1.5
		S649.8,560.5,649,560.5z"/>
	<path id="Path_733"  d="M555,572h94"/>
	<path id="Path_733_-_Outline"  d="M649,573.5h-94c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h94c0.8,0,1.5,0.7,1.5,1.5
		S649.8,573.5,649,573.5z"/>
	<path id="Path_734"  d="M555,585h94"/>
	<path id="Path_734_-_Outline"  d="M649,586.5h-94c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h94c0.8,0,1.5,0.7,1.5,1.5
		S649.8,586.5,649,586.5z"/>
	<path id="Path_735"  d="M555,598h94"/>
	<path id="Path_735_-_Outline"  d="M649,599.5h-94c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h94c0.8,0,1.5,0.7,1.5,1.5
		S649.8,599.5,649,599.5z"/>
	<path id="Path_736"  d="M555,611h94"/>
	<path id="Path_736_-_Outline"  d="M649,612.5h-94c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h94c0.8,0,1.5,0.7,1.5,1.5
		S649.8,612.5,649,612.5z"/>
	<path id="Path_737_00000118380326025877497420000000227545093644124861_"  d="M554,611"/>
	<path id="Path_737_00000143605895203385462930000003033314858265767570_"  d="M554,559"/>
	<path id="Path_742"  d="M650,559v52"/>
	<path id="Path_742_-_Outline"  d="M650,612.5c-0.8,0-1.5-0.7-1.5-1.5v-52c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v52
		C651.5,611.8,650.8,612.5,650,612.5z"/>
</g>
</svg>):(<svg xmlns="http://www.w3.org/2000/svg"  className={(props.active)?'bar-item-active':'bar-item-normal'} width="106" height="55" viewBox="0 0 106 55">
  <g id="Group_20" data-name="Group 20" transform="translate(-545.5 -557.5)">
    <path id="Path_732" data-name="Path 732" d="M0,0H94" transform="translate(555 559)" fill="#d6d7d9"/>
    <path id="Path_732_-_Outline" data-name="Path 732 - Outline" d="M94,1.5H0A1.5,1.5,0,0,1-1.5,0,1.5,1.5,0,0,1,0-1.5H94A1.5,1.5,0,0,1,95.5,0,1.5,1.5,0,0,1,94,1.5Z" transform="translate(555 559)" fill="#d6d7d9"/>
    <path id="Path_733" data-name="Path 733" d="M0,0H94" transform="translate(555 572)" fill="#d6d7d9"/>
    <path id="Path_733_-_Outline" data-name="Path 733 - Outline" d="M94,1.5H0A1.5,1.5,0,0,1-1.5,0,1.5,1.5,0,0,1,0-1.5H94A1.5,1.5,0,0,1,95.5,0,1.5,1.5,0,0,1,94,1.5Z" transform="translate(555 572)" fill="#d6d7d9"/>
    <path id="Path_734" data-name="Path 734" d="M0,.5H94" transform="translate(555 584.5)" fill="#d6d7d9"/>
    <path id="Path_734_-_Outline" data-name="Path 734 - Outline" d="M94,2H0A1.5,1.5,0,1,1,0-1H94a1.5,1.5,0,0,1,0,3Z" transform="translate(555 584.5)" fill="#d6d7d9"/>
    <path id="Path_735" data-name="Path 735" d="M0,.5H94" transform="translate(555 597.5)" fill="#d6d7d9"/>
    <path id="Path_735_-_Outline" data-name="Path 735 - Outline" d="M94,2H0A1.5,1.5,0,1,1,0-1H94a1.5,1.5,0,0,1,0,3Z" transform="translate(555 597.5)" fill="#d6d7d9"/>
    <path id="Path_736" data-name="Path 736" d="M0,0H94" transform="translate(555 611)" fill="#d6d7d9"/>
    <path id="Path_736_-_Outline" data-name="Path 736 - Outline" d="M94,1.5H0A1.5,1.5,0,0,1-1.5,0,1.5,1.5,0,0,1,0-1.5H94A1.5,1.5,0,0,1,95.5,0,1.5,1.5,0,0,1,94,1.5Z" transform="translate(555 611)" fill="#d6d7d9"/>
    <path id="Path_737" data-name="Path 737" d="M0,0V52" transform="translate(554 559)" fill="#d6d7d9"/>
    <path id="Path_737_-_Outline" data-name="Path 737 - Outline" d="M0,53.5A1.5,1.5,0,0,1-1.5,52V0A1.5,1.5,0,0,1,0-1.5,1.5,1.5,0,0,1,1.5,0V52A1.5,1.5,0,0,1,0,53.5Z" transform="translate(554 559)" fill="#d6d7d9"/>
    <path id="Path_738" data-name="Path 738" d="M0,0V52" transform="translate(547 559)" fill="#d6d7d9"/>
    <path id="Path_738_-_Outline" data-name="Path 738 - Outline" d="M0,53.5A1.5,1.5,0,0,1-1.5,52V0A1.5,1.5,0,0,1,0-1.5,1.5,1.5,0,0,1,1.5,0V52A1.5,1.5,0,0,1,0,53.5Z" transform="translate(547 559)" fill="#d6d7d9"/>
    <path id="Path_739" data-name="Path 739" d="M0,0V8" transform="translate(555 611) rotate(90)" fill="#d6d7d9"/>
    <path id="Path_739_-_Outline" data-name="Path 739 - Outline" d="M0,9.5A1.5,1.5,0,0,1-1.5,8V0A1.5,1.5,0,0,1,0-1.5,1.5,1.5,0,0,1,1.5,0V8A1.5,1.5,0,0,1,0,9.5Z" transform="translate(555 611) rotate(90)" fill="#d6d7d9"/>
    <path id="Path_740" data-name="Path 740" d="M0,0V8" transform="translate(555 559) rotate(90)" fill="#d6d7d9"/>
    <path id="Path_740_-_Outline" data-name="Path 740 - Outline" d="M0,9.5A1.5,1.5,0,0,1-1.5,8V0A1.5,1.5,0,0,1,0-1.5,1.5,1.5,0,0,1,1.5,0V8A1.5,1.5,0,0,1,0,9.5Z" transform="translate(555 559) rotate(90)" fill="#d6d7d9"/>
    <path id="Path_742" data-name="Path 742" d="M0,0V52" transform="translate(650 559)" fill="#d6d7d9"/>
    <path id="Path_742_-_Outline" data-name="Path 742 - Outline" d="M0,53.5A1.5,1.5,0,0,1-1.5,52V0A1.5,1.5,0,0,1,0-1.5,1.5,1.5,0,0,1,1.5,0V52A1.5,1.5,0,0,1,0,53.5Z" transform="translate(650 559)" fill="#d6d7d9"/>
  </g>
</svg>
)}
</div>
 );
};

export default BarItem;
