import React from "react";
import "./SelectionItem.css";

const SelectionItem = (props) => {
  const initiateInputHandler = () => {
    props.onInitiateInput(props.type);
  };

  let markup;
  if (props.valueIsSelected) {
    markup= <div onClick={initiateInputHandler} className={`item-image-wrapper ${props.valueIsSelected?'item-completed':''}`}>
    <div className="selected-label">{props.valueSelected}</div>
  </div>
  } else {
    markup = (
      <div onClick={initiateInputHandler} className={`item-image-wrapper ${props.controlDisabled?'control-disabled':''}`}>
        <img
          className="image-active"
          width="70"
          height="70"
          src={props.itemImageAct}
        />
        <img
          className="image-idle"
          width="70"
          height="70"
          src={props.itemImage}
        />
      </div>
    );
  }

  return (
    <div className="form-item">
      {markup}
      <div className="form-item-label">{props.title}</div>
    </div>
  );
};

export default SelectionItem;
