import React, { useState } from "react";
import "./ScaleItems.css";

const ScaleItems = (props) => {
  const [isSelected, setIsSelected] = useState(false);

  const textItemClickHandler = (event) => {
    props.onValueSelected(event.target.name);
    setIsSelected(!isSelected);
  };

  return (
    <div className="input-value d-flex justify-content-between align-items-center mb-2">
      <button name="major"
        className={`text-selection-item ${
          props.currentValue == "major" ? "text-selection-active" : ""
        }`}
        onClick={textItemClickHandler}
      >
       Major
      </button>
      <button name="minor"
        className={`text-selection-item ${
          props.currentValue == "minor" ? "text-selection-active" : ""
        }`}
        onClick={textItemClickHandler}
      >
       Minor
      </button>
    </div>
  );
};

export default ScaleItems;
