import React, { ReactFragment } from "react";
import piano from "../../img/piano-list.png";
import download from "../../img/download.png";
import playFilled from '../../img/playback/play-filled.png';
import './SongItem.css';
const SongItem = (props) => {

  const songItemSelectionHandler = (event) => {
    console.log('hello');
    props.onSongSelection(event.target.getAttribute('data-songindex'));
  };


  return (
    <React.Fragment>
      <div className="table-row" key={props.index.toString() + "song"}>
        <div className="title-cell" >
          <div className="cell-image-wrapper">
            <img
              src={piano}
              width="40"
              height="40" 
            />
          </div>
          {props.title}
        </div>
        <div>-</div>
        <div>
          {props.bpm}<span className="bpm-label">BPM</span>
        </div>
        <div> {props.numberOfBars}</div>
        <div>{props.scale}</div>
        <div>-</div>
        <div>{props.timeSignature}</div>
        <a  className="play-midi-icon"><img onClick={songItemSelectionHandler} data-songindex={props.title}  src={playFilled}/></a>
        <a href={`https://allinoneapi.mercuryorbitmusic.net/neuralsapi/midiout/${props.title}`} className="download-icon"><img src={download}/></a>
      </div>
    </React.Fragment>
  );
};

export default SongItem;
