import React from "react";

import "./SongList.css";
import SongItem from "./SongItem";
import PendingItem from "./PendingItem";
import Loader from "../UI/Loader";
//import { midiList } from "../../HelperPlayback";

const SongList = (props) => {
  const closeSongListHandler = (event) => {
    props.onUpdateSelection(event);
  };

  const songSelectionHandler = (value) => {
    props.songSelection(value);
  };

  

  return (
    <div
      className={`song-list-wrapper ${
        props.status === "songlist" ? "show" : ""
      } `}
    >
      <div className="song-list">
        {" "}
        <div className="d-flex py-3 justify-content-end">
          <button
            className="btn"
            onClick={closeSongListHandler}
            name="tracknotes"
          >
            CLOSE
          </button>
        </div>
        {props.midiList.length > 0 && (
          <React.Fragment>
            <div className="table-header">
              <div className="title-cell">Title</div>
              <div>Style</div>
              <div>Tempo</div>
              <div>Bars</div>
              <div> Scale</div>
              <div> Tone</div>
              <div> TimeSig.</div>
              <div>Play</div>
              <div>Download</div>
            </div>
            <div className="list-table">
              {props.isGenerating && <PendingItem />}
              {props.midiList.map((song, index) => (
                <SongItem
                  key={index + "song-item"}
                  index={1}
                  onSongSelection={songSelectionHandler}
                  title={song.name}
                  bpm={1}
                  numberOfBars={1}
                  scale={1}
                  timeSignature={1}
                />
              ))}
            </div>
          </React.Fragment>
        )}
        {props.midiList.length === 0 && (
          <div className="midi-selection-pending d-flex flex-column align-items-center justify-content-center">
            <Loader /> <div className="mt-3">FETCHING SONGS FROM LIBRARY</div>
          </div>
        )}
         
        <div className="d-flex justify-content-center py-3">
          <button
            className="btn"
            onClick={closeSongListHandler}
            name="newtrack"
          >
            NEW PROJECT
          </button>
        </div>
      </div>
    </div>
  );
};

export default SongList;
