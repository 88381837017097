import React, { useState, useRef, useEffect, useCallback } from "react";
import TrackControl from "./components/InstrumentTracks/TrackControl";
import TimeBar from "./components/InstrumentTracks/TimeBar";
import TrackNotes from "./components/InstrumentTracks/TrackNotes";
import TimeIndicator from "./components/InstrumentTracks/TimeIndicator";
import SongList from "./components/SongList/SongList";
import SelectionForm from "./components/SelectionForm/SelectionForm";
import EditScreen from "./components/InstrumentEdit/EditScreen";
import Menu from "./components/UI/Menu";
import Loader from "./components/UI/Loader";
import Button from "./components/UI/Button";
import PlaybackControls from "./components/MenuBar/PlaybackControls";
import QuickSelectionArea from './components/SelectionForm/QuickSelectionArea';
import * as Tone from "tone";
import { Midi } from "@tonejs/midi";

import logo from "./img/logo.svg";
import {
  playBackStart,
  samplesPrepare,
  sampleFilesLoad,
  playBackStop,
  playBackComplete,
  muteChannel,
  soloMute,
  changeVolume,
  totalRunningTime,
  getPlayBackTimeTicks
} from "./HelperPlayback";

const stageWidth = 3520;
const xSnaps = 33;
const cellWidth = stageWidth / 32;
const tickWidth = cellWidth / 48;
const tickWidth2 = cellWidth / 48;
let channels = [];
let tracks = [];
const bpm = 150;
const ppq = 12;



function App() {
  const [activeScreen, setActiveScreen] = useState("songlist");
  const [playBackState, setPlayBackState] = useState("stopped");
  const [midiRunningTime, setMidiRunningTime]= useState();
  const [selectedSongIndex, setSelectedSongIndex] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [midiList, setMidiList] = useState([]);
  const [newMidiFilePending, setNewMidiFilePending] = useState();
  const [newMidiSelected, setNewMidiSelected] = useState('');
  const [midiIsReady, setMidiIsReady] = useState(false);
  const [currentMidiSpecs, setCurrentMidiSpecs] = useState('');
  const [quickSelectionActive, setQuickSelectionActive] = useState(false);
  const vocalsRef = useRef();
  const guitarRef = useRef();
  const bassRef = useRef();
  const drumsRef = useRef();
  const indicatorRef = useRef();

  const  instruments = sampleFilesLoad();

  useEffect(() => {
    songListFetch();
  }, []);

  useEffect(() => {
    songListFetch();
  }, [newMidiFilePending]);

  async function songListFetch() {
    setIsGenerating(true);
    const response = await fetch(
      "https://allinoneapi.mercuryorbitmusic.net/neuralsapi/fetch_sound_files"
    );
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const data = await response.json();

    const transformedSoundFiles = data.sound_file.map((filesData) => {
    
      return {
        date_created: filesData.date_created,
        name: filesData.name,
        midi_file: filesData.midi_file,
        midi_specs:filesData.midi_specs
      };
    });
    // if(transformedSoundFiles.length==0)
   
    setCurrentMidiSpecs(transformedSoundFiles[0].midi_specs)
    setMidiList(transformedSoundFiles);
    setIsGenerating(false);
  }

  const formInputCompleted = (params) => {
    setIsGenerating(true);

    setActiveScreen("songlist");
    const response = fetch(
      "https://allinoneapi.mercuryorbitmusic.net/neuralsapi/run_generation",
      { method: "POST",  headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },body:JSON.stringify(params) }
    ).then(async (response) => {
      generationProgress(response.headers.get("Location"));
    });
  };

  const quickFormInputCompleted = (params) => {
    setMidiIsReady(false);
    setNewMidiSelected('');
    setIsGenerating(true);
    
   
    const response = fetch(
      "https://allinoneapi.mercuryorbitmusic.net/neuralsapi/run_generation",
      { method: "POST",  headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },body:JSON.stringify(params) }
    ).then(async (response) => {
      generationProgressQuick(response.headers.get("Location"),'quick');
    });
  };

  const generationProgressQuick = (status_url) =>{
    fetch(status_url, { method: "GET" })
    .then((response) => response.json())
    .then((data) => {
      if (data["state"] != "PENDING" && data["state"] != "PROGRESS") {
        setNewMidiFilePending(data["midi_file"]);
        songSelectionHandler(data["midi_file"]);
        setIsGenerating(false);
      } else {
        setTimeout(() => {
          generationProgressQuick(status_url);
        }, 1000);
      }
    });
  }

  const generationProgress = (status_url) => {
    fetch(status_url, { method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        if (data["state"] != "PENDING" && data["state"] != "PROGRESS") {
          setNewMidiFilePending(data["midi_file"]);
        } else {
          setTimeout(() => {
            generationProgress(status_url);
          }, 1000);
        }
      });
  };

  useEffect(() => {
    if(newMidiSelected.length!==0){
      indicatorRef.current.initiateIndicator();
    }
  }, [newMidiSelected]);


  async function newMidiFile(midiSelected) {
    setActiveScreen("tracknotes");
     let response = await Midi.fromUrl(
       "https://allinoneapi.mercuryorbitmusic.net/neuralsapi/midiout/"+midiSelected 
     );
     console.log(midiSelected);
     midiSamplesLoad(response);
     setMidiIsReady(true);
     setNewMidiSelected(midiSelected);
     
   }
 


  const midiSamplesLoad = (midiSelected) => {
    setSelectedSongIndex(midiSelected);
    tracks = midiSelected.tracks;
    channels = samplesPrepare(midiSelected, instruments);
    setMidiRunningTime(totalRunningTime(tracks));
   
  };

  const songSelectionHandler = (midiSelected) => {

    setMidiIsReady(false);
    setNewMidiSelected('');
    newMidiFile(midiSelected);
  };
  const handleActiveScreen = (event) => {
    setActiveScreen(event.target.name);
  };

  const findInstrument = (inst, value) => {
    return inst.name === value;
  };

  const handleChangePlayback = () => {
    if (playBackState === "stopped") {
      playBackStart(channels);
    } else {
      playBackStop(channels);
    }
   setPlayBackState(playBackState == "stopped" ? "started" : "stopped");
  };

  useEffect(() => {
    let interval;
    if(playBackState==='started'){
      interval = setInterval(() => {
        // console.log('current: '+getPlayBackTimeTicks()+' total: '+midiRunningTime);
        if(getPlayBackTimeTicks()>=midiRunningTime){
          playBackComplete();
          setTimeout(()=>{indicatorRef.current.initiateIndicator();},300); 
          setPlayBackState(playBackState == "stopped" ? "started" : "stopped");
        }
      }, 50);
    }
   
    return () => clearInterval(interval);
  }, [playBackState]);

 
  const handleSkipForward = () => {
    indicatorRef.current.skipForward();
  };

  const handleSkipBackward = () => {
    indicatorRef.current.skipBackward();
  };

  const handleFastForward = () => {
    indicatorRef.current.fastForward();
  };

  const handleChangeSolo = (channel, type) => {
    if (channel != "Vocals") {
      vocalsRef.current.handleVolumeChange();
    }

    if (channel != "Drums") {
      drumsRef.current.handleVolumeChange();
    }

    if (channel != "Bass") {
      bassRef.current.handleVolumeChange();
    }

    if (channel != "Guitar") {
      guitarRef.current.handleVolumeChange();
    }
    soloMute(channels, channel, type);
  };

  const handleChangeMute = (soundState, channel) => {
    muteChannel(channels, channel, soundState);
    switch (channel) {
      case "Vocals":
        vocalsRef.current.handleVolumeChange();
        break;
      case "Guitar":
        guitarRef.current.handleVolumeChange();
        break;
      case "Bass":
        bassRef.current.handleVolumeChange();
        break;
      case "Drums":
        drumsRef.current.handleVolumeChange();
        break;
    }
  };

  const handleTimeBarClick = (e) => {};

  const handleChangeVolume = (value,instrument) => {
    changeVolume(value,instrument);
  };

  const newTrackHandler = () => {
    setQuickSelectionActive(!quickSelectionActive);
  };

  return (
    <div>
      
      <SongList
        status={activeScreen}
        onUpdateSelection={handleActiveScreen}
        midiList={midiList}
        isGenerating={isGenerating}
        songSelection={songSelectionHandler}
      />
      <SelectionForm
        status={activeScreen}
        onParametersCompleted={formInputCompleted}
      />
        {/* <EditScreen 
      status={activeScreen}   
      ></EditScreen> */}

      <div className="menu-bar">
        <div className="logo">
          <a href="#">
            <img src={logo} width="160" height="45" />
          </a>
        </div>
        <PlaybackControls
          onChangePlayBack={handleChangePlayback}
          onSkipBackward={handleSkipBackward}
          onSkipForward={handleSkipForward}
          onFastForward={handleFastForward}
        />
        <div className="navigation d-flex justify-content-end">
          <button className="btn me-2">
            SAVE PROJECT
          </button>
          <button className="btn me-2" onClick={newTrackHandler}>
            NEW TRACK
          </button>
          <button className="btn" name="songlist" onClick={handleActiveScreen}>
            SONG LIST
          </button>
        </div>
      </div>
      <QuickSelectionArea quickGenerateHandler={quickFormInputCompleted} currentMidi={currentMidiSpecs} quickSelectionGenRunning={isGenerating} quickSelectionActive={quickSelectionActive} />
     
      {!midiIsReady &&
        <div className="midi-selection-pending d-flex flex-column align-items-center justify-content-center">
          <Loader/> <div className="mt-3">WAITING FOR SONG TO BE LOADED</div></div>
      }
      {newMidiSelected && 
      <div className="tracks-area">
        <div className="track-controls-wrapper">
          {tracks.map((track, index) => (
            <TrackControl
              key={track.channel + index}
              volume="100"
              instrument={track.name}
              onChangeMute={handleChangeMute}
              onChangeSolo={handleChangeSolo}
              onChangeVolume={handleChangeVolume}
            />
          ))}
        </div>
        <div className="pianoroll-wrapper">
          <TimeIndicator
            tickWidth={tickWidth2}
            playBackState={playBackState}
            stageWidth={stageWidth}
            cellWidth={cellWidth}
            ref={indicatorRef}
          />
          <TimeBar
            stageWidth={stageWidth}
            cellWidth={cellWidth}
            xSnaps={xSnaps}
          />
          {tracks.map((track, index) => (
            <TrackNotes
              ref={
                track.name == "Vocals"
                  ? vocalsRef
                  : track.name == "Guitar"
                  ? guitarRef
                  : track.name == "Bass"
                  ? bassRef
                  : drumsRef
              }
              key={track.name + "1"}
              tickWidth={tickWidth}
              onOpenEditScreen={handleActiveScreen}
              notes={track.notes}
            />
          ))}
        </div>
      </div>}
    
    </div>
  );
}

export default App;
