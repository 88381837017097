import React from "react";
import { Stage, Layer, Rect, Line,Text} from "react-konva";
import styles from "./TimeBar.css";

const TimeBar = (props) => {

let xSnaps = props.xSnaps;
let items=[];

  for(var i=0;i<xSnaps;i++){
    items.push(
        <Line
        key={"line"+i}
        x= {i * props.cellWidth}
        points= {[0, 50, 0, 25]}
        stroke = {i==0  ?"rgba(0, 0, 0, 0)":"rgba(0, 0, 0, 0.2)"}
        strokeWidth= {1}
        />
    )
  }

  let itemsText=[];
  for(var i=0;i<xSnaps;i++){
    itemsText.push(
        <Text key={i==0  ? "":i}
        x= {i * props.cellWidth - 4}
        y= {8}
        text= {i==0  ? "":i}
        fontSize= {16}
        fontFamily= "Calibri"
        fill= "#96989F"
        />
    )
  }
    
  return (
    <div className="time-bar">
      <div className="time-ruler">
        <Stage width={props.stageWidth} height={49}>
          <Layer>
            <Rect
              fill="#F5F5F5"
              x={0}
              y={0}
              stroke="#C3C3C3"
              strokeWidth={1}
              cornerRadius={0}
              width={3520}
              height={48}
            />
          </Layer>
          <Layer>
           {items}
           {itemsText}
          </Layer>
        </Stage>
      </div>
      <div className="notes"></div>
    </div>
  );
}

export default TimeBar;
