import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { gsap } from "gsap";
import styles from "./TimeIndicator.css";
import indicator from "../../img/Indicator.png";
import { getPlayBackTimeTicks, setPlayBackTime } from "../../HelperPlayback";

const TimeIndicator = forwardRef((props, ref) => {
  const indRef = useRef();


  const initiateIndicator = () =>{
    gsap.to(indRef.current, {
      x: 0,
    });
  }

  const animateIndicator = () => {
    if (props.playBackState === "started") {
      let time = getPlayBackTimeTicks();
      let position = time * props.tickWidth;
      gsap.to(indRef.current, {
        x: position,duration:0.1
      });
    }
  };


  useImperativeHandle(ref, () => ({
    skipForward: skipForward,
    skipBackward: skipBackward,
    fastForward: fastForward,
    initiateIndicator:initiateIndicator
  }));

  const skipForward = () => {
    let time = getPlayBackTimeTicks();

    let position = time * props.tickWidth;
    let nextPosition;
    for (var i = 1; i < props.cellWidth * 32; i++) {
      if (i * props.cellWidth > position) {
        nextPosition = i;
        break;
      }
    }
    gsap.to(indRef.current, {
      x: nextPosition * props.cellWidth,
    });
    setPlayBackTime(nextPosition);
  };

  const skipBackward = () => {
    let time = getPlayBackTimeTicks();
    let position = time * props.tickWidth;
    let nextPosition;
    for (var i = props.cellWidth * 32; i > 1; i--) {
      if (i * props.cellWidth < position) {
        nextPosition = i;
        break;
      }
    }

    gsap.to(indRef.current, {
      x: nextPosition * props.cellWidth,
    });

    setPlayBackTime(nextPosition);
  };


  const fastForward = () =>{

  }

  useEffect(() => {
    const interval = setInterval(() => {
      animateIndicator();
    }, 50);
    return () => clearInterval(interval);
  }, [props.playBackState]);

  return (
    <div ref={indRef} className="time-indicator">
      <img className="" src={indicator} />
      <div className="indicator-line"></div>
    </div>
  );
});

export default TimeIndicator;
