import React, { useState, useImperativeHandle, forwardRef,useRef } from "react";
import { Stage, Layer, Rect, Line } from "react-konva";
import "./TrackNotes.css";
import editPolygon from "../../img/edit-polygon.png";
const TrackNotes = forwardRef((props, ref) => {
  const [hasVolume, setHasVolume] = useState(true);
  const [editVisible, setEditVisible] = useState(false);
  const notesWrapperRef = useRef();
  const editRef =useRef();
  const handleVolumeChange = () => {
    setHasVolume(!hasVolume);
  };

  useImperativeHandle(ref, () => ({
    handleVolumeChange: handleVolumeChange,
  }));

  const revealEditForm = (e) =>{
     let rect = notesWrapperRef.current.getBoundingClientRect();
     let width =editRef.current.offsetWidth;
     let x = e.evt.clientX - rect.left; 
     editRef.current.style.setProperty('left', x-250+'px');
    setEditVisible(editVisible?false:true);
  }

  const openEditScreen = (e) =>{
    props.onOpenEditScreen(e);
  }

  let notes = [];
  {
    props.notes.forEach((note, index) => {
      notes.push(
        <Rect
          key={note.name + note.duration + index}
          x={props.tickWidth * note.ticks}
          y={180 - note.midi * 1.1}
          width={props.tickWidth * note.durationTicks}
          height={4}
          fill={hasVolume ? "#B38DB5" : "#9D9D9D"}
          opacity={1}
        />
      );
    });
  }

  return (
    <div className="tracknotes-wrapper"  ref={notesWrapperRef}>
      <Stage width={3520} height={180} onClick={revealEditForm} >
        <Layer>
          <Rect
            fill={hasVolume ? "#420F65" : "#000000"}
            x={0}
            y={5}
            opacity={hasVolume ? 0.8 : 0.3}
            cornerRadius={8}
            width={3520}
            height={169}
          />
        </Layer>
        <Layer>{notes}</Layer>
      </Stage>
      <div ref={editRef} className={`tracknotes-edit-wrapper ${
        editVisible ? "tracknotes-edit-visible" : "" }`}>
        <img
          src={editPolygon}
          alt="edit-polygon"
          className="edit-polygon"
          width="30"
          height="16"
        />
        <div className="tracknotes-edit">
          <button>Copy</button>
          <button>Delete</button>
          <button>Trim</button>
          <button name="pianorolledit" >Edit Piano Roll</button>
          <button>Change Instrument</button>
          <button>Options</button>
        </div>
      </div>
    </div>
  );
});

export default TrackNotes;
