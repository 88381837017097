import * as Tone from "tone";
import Samples from "./Config";

let bassSampler,guitarSampler,vocalsSampler,hiHatSampler, hiHatOpenSampler,snareSampler,kickSampler,rideSampler,crash1Sampler, crash2Sampler, tom1Sampler,tom2Sampler,tom3Sampler,chinaSampler;
// const vol = new Tone.Volume(-5).toDestination();
// const volBass = new Tone.Volume(-20).toDestination();
// const guitarVol = new Tone.Volume(-10).toDestination();
const volValues = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0];
// const volThresholds = [0, 0, -11, -14, -17, -20, -23, -26, -29, -32, -35];
const volThresholds = [-5, -7, -10, -12,-13, -15, -18,-20, -25, -30, -35];
const volThresholdsGuitar = [-7,-8,-9,-10,-11,-12,-13,-20, -25, -30, -35];
//const gain = new Tone.Gain(0.8).toDestination();

export const playBackStart = (channels) => {
  Tone.Transport.start();
  channels.forEach((item) => {
    item.start();
  });
  Tone.start();
};

export const playBackStop = (channels) => {
  Tone.Transport.pause();
};

export const playBackComplete = () =>{
  Tone.Transport.stop();
}

export const soloMute = (channels, selectedChannel, type) => {
  if (selectedChannel != "Vocals") {
    channels[0].mute = !type;
  }

  if (selectedChannel != "Drums") {
    channels[3].mute = !type;
    channels[4].mute = !type;
    channels[5].mute = !type;
  }

  if (selectedChannel != "Bass") {
    channels[2].mute = !type;
  }

  if (selectedChannel != "Guitar") {
    channels[1].mute = !type;
  }
};

export const muteChannel = (channels, selectedChannel, soundState) => {
  switch (selectedChannel) {
    case "Vocals":
      channels[0].mute = soundState;
      break;
    case "Guitar":
      channels[1].mute = soundState;
      break;
    case "Bass":
      channels[2].mute = soundState;
      break;
    case "Drums":
      channels[3].mute = soundState;
      channels[4].mute = soundState;
      channels[5].mute = soundState;
      break;
  }
};

export const getPlayBackTimeTicks = () => {
  return Tone.Transport.ticks;
};

export const totalRunningTime = (tracks) =>{
  let maxTime = 0;
  tracks.forEach(track =>{
    maxTime = Math.max(maxTime,track.endOfTrackTicks)
  });
  return maxTime;
}


export const setPlayBackTime = (ticksPos) => {
  Tone.Transport.seconds = ticksPos + ":0:0";
};

export const fastForward = () => {};

export const sampleFilesLoad = () => {
  return Samples.load({
   
    instruments: [
      "piano",
      "bass-electric",
      "guitar-electric",
      "china",
      "kick",
      "snare",
      "snareStick",
      "tom1",
      "tom2",
      "tom3",
      "hihat",
      "hihatOpen",
      "crash1",
      "crash2",
      "ride",
      "china",
    ],
  });
};

const notesClean = (arr) =>{
  let clean = arr.filter((arr, index, self) =>
    index === self.findIndex((t) => (t.midi === arr.midi && t.ticks === arr.ticks && t.duration === arr.duration)))

  return clean;  
}

export const samplesPrepare = (midiSelected, instruments) => {

  Tone.Transport.stop();
  Tone.Transport.cancel();

  Tone.Transport.PPQ = midiSelected.header.ppq;
  let channels = [];
  let notePrev;
  let notesArray,
    instrumentsArr = [];
  
  // const dist = new Tone.Distortion(0.9).toDestination();
  Object.entries(instruments).forEach((item) => {
    const [key, inst] = item;
    inst.name = key;
    instrumentsArr.push(inst);
    inst.toDestination();
  });

 
  let currentTempo = midiSelected.header.tempos[0].bpm;
  Tone.Transport.bpm.value =currentTempo;
  let trackNotesArray = [];
  midiSelected.tracks.forEach((track) => {
    if (track.name == "Guitar") {
      trackNotesArray = [];
      let cleanNotes = notesClean(track.notes);
      cleanNotes.forEach((note) => {
    
        let bassObj = {
          time: String(note.ticks) + "i",
          note: Tone.Frequency(note.midi, "midi").toNote(),
          dur: String(note.durationTicks) + "i",
        };
        trackNotesArray.push(bassObj);
      });

      guitarSampler = instrumentsArr.find(
        ({ name }) => name === "guitar-electric"
      );
     

      let channelItem = new Tone.Part((time, event) => {
        guitarSampler.triggerAttackRelease(event.note, event.dur, time);
        // guitarSampler.connect(guitarVol);
      
      }, trackNotesArray);
      guitarSampler.volume.value = -7;
      channels.push(channelItem);
    }
    if (track.name == "Bass") {
      trackNotesArray = [];
      track.notes.forEach((note) => {
        let bassObj = {
          time: String(note.ticks) + "i",
          note: Tone.Frequency(note.midi, "midi").toNote(),
          dur: String(note.durationTicks) + "i",
        };
        trackNotesArray.push(bassObj);
      });

      bassSampler = instrumentsArr.find(({ name }) => name === "bass-electric");
      let channelItem = new Tone.Part((time, event) => {
        // bassSampler.connect(volBass);
        bassSampler.triggerAttackRelease(event.note, event.dur, time, 1);
      }, trackNotesArray);
      bassSampler.volume.value = -5;
      channels.push(channelItem);
    }
    if (track.name == "Vocals") {
      
      trackNotesArray = [];
      let cleanNotes = notesClean(track.notes);
      cleanNotes.forEach((note) => {
      
        let bassObj = {
          time: String(note.ticks) + "i",
          note: Tone.Frequency(note.midi, "midi").toNote(),
          dur: String(note.durationTicks) + "i",
        };
        trackNotesArray.push(bassObj);

      });

      vocalsSampler = instrumentsArr.find(({ name }) => name === "piano");
      let channelItem = new Tone.Part((time, event) => {
        // vocalsSampler.connect(vol)
        vocalsSampler.triggerAttackRelease(event.note, event.dur, time);       
      }, trackNotesArray);
      vocalsSampler.volume.value = -5;
      channels.push(channelItem);
    }

    if (track.name == "Drums") {
      let kick = [];
      let ride = [];
      let snare = [];
      let hihat = [];
      let hihatOpen = [];
      let china = [];
      let tom1 = [];
      let tom2 = [];
      let tom3 = [];
      let crash1 = [];
      let crash2 = [];
      
      let noteObj;
      track.notes.forEach((note) => {
        if (note.midi == 36 || note.midi == 35) {
          noteObj = {
            time: String(note.ticks) + "i",
            note: Tone.Frequency(note.midi, "midi").toNote(),
            dur: String(note.durationTicks) + "i",
          };
          kick.push(noteObj);
        }
        if (note.midi == 38 || note.midi == 40) {
          noteObj = {
            time: String(note.ticks) + "i",
            note: Tone.Frequency(note.midi, "midi").toNote(),
            dur: String(note.durationTicks) + "i",
          };
          snare.push(noteObj);
        }
        if (note.midi == 52 ) {
          noteObj = {
            time: String(note.ticks) + "i",
            note: Tone.Frequency(note.midi, "midi").toNote(),
            dur: String(note.durationTicks) + "i",
          };
          china.push(noteObj);
        }
        if (note.midi == 51 || note.midi == 53 || note.midi == 56 || note.midi == 59) {
          noteObj = {
            time: String(note.ticks) + "i",
            note: Tone.Frequency(note.midi, "midi").toNote(),
            dur: String(note.durationTicks) + "i",
          };
          ride.push(noteObj);
        }

        if (note.midi == 49) {
          noteObj = {
            time: String(note.ticks) + "i",
            note: Tone.Frequency(note.midi, "midi").toNote(),
            dur: String(note.durationTicks) + "i",
          };
          crash1.push(noteObj);
        }

        if (note.midi == 57) {
          noteObj = {
            time: String(note.ticks) + "i",
            note: Tone.Frequency(note.midi, "midi").toNote(),
            dur: String(note.durationTicks) + "i",
          };
          crash2.push(noteObj);
        }
        if (note.midi == 38) {
          noteObj = {
            time: String(note.ticks) + "i",
            note: Tone.Frequency(note.midi, "midi").toNote(),
            dur: String(note.durationTicks) + "i",
          };
          hihat.push(noteObj);
        }

        if (note.midi == 46) {
          noteObj = {
            time: String(note.ticks) + "i",
            note: Tone.Frequency(note.midi, "midi").toNote(),
            dur: String(note.durationTicks) + "i",
          };
          hihatOpen.push(noteObj);
        }

        if (note.midi == 48 || note.midi == 50) {
          noteObj = {
            time: String(note.ticks) + "i",
            note: Tone.Frequency(note.midi, "midi").toNote(),
            dur: String(note.durationTicks) + "i",
          };
          tom1.push(noteObj);
        }

        if (note.midi == 45 || note.midi == 47) {
          noteObj = {
            time: String(note.ticks) + "i",
            note: Tone.Frequency(note.midi, "midi").toNote(),
            dur: String(note.durationTicks) + "i",
          };
          tom2.push(noteObj);
        }

        if (note.midi == 41 || note.midi == 43) {
          noteObj = {
            time: String(note.ticks) + "i",
            note: Tone.Frequency(note.midi, "midi").toNote(),
            dur: String(note.durationTicks) + "i",
          };
          tom3.push(noteObj);
        }

      });
 

      kickSampler = instrumentsArr.find(({ name }) => name === "kick");
      let channelItemKick = new Tone.Part((time, event) => {
        // kickSampler.connect(vol);
        kickSampler.triggerAttackRelease(event.note, event.dur, time);
       
      }, kick);
      channels.push(channelItemKick);

      snareSampler = instrumentsArr.find(({ name }) => name === "snare");
      let channelItemSnare = new Tone.Part((time, event) => {
        // snareSampler.connect(vol);
        snareSampler.triggerAttackRelease(event.note, event.dur, time);
      
      }, snare);
      channels.push(channelItemSnare);

      rideSampler = instrumentsArr.find(({ name }) => name === "ride");
      let channelItemRide = new Tone.Part((time, event) => {
        // snareSampler.connect(vol);
        rideSampler.triggerAttackRelease(event.note, event.dur, time);
      
      }, ride);
      channels.push(channelItemRide);
     
      crash1Sampler = instrumentsArr.find(({ name }) => name === "crash1");
      let channelItemCrash1 = new Tone.Part((time, event) => {
        // snareSampler.connect(vol);
        crash1Sampler.triggerAttackRelease(event.note, event.dur, time);
      
      }, crash1);
      channels.push(channelItemCrash1);

      crash2Sampler = instrumentsArr.find(({ name }) => name === "crash1");
      let channelItemCrash2 = new Tone.Part((time, event) => {
        // snareSampler.connect(vol);
        crash2Sampler.triggerAttackRelease(event.note, event.dur, time);
      
      }, crash2);
      channels.push(channelItemCrash2)
     
      hiHatSampler = instrumentsArr.find(({ name }) => name === "hihat");
      let channelItemHiHat = new Tone.Part((time, event) => {
        // hiHatSampler.connect(vol);
        hiHatSampler.triggerAttackRelease(event.note, event.dur, time);

      }, hihat);
      channels.push(channelItemHiHat);

      hiHatOpenSampler = instrumentsArr.find(({ name }) => name === "hihatOpen");
      let channelItemHiHatOpen = new Tone.Part((time, event) => {
        // hiHatSampler.connect(vol);
        hiHatOpenSampler.triggerAttackRelease(event.note, event.dur, time);

      }, hihatOpen);
      channels.push(channelItemHiHatOpen);

      tom1Sampler = instrumentsArr.find(({ name }) => name === "tom1");
      let channelItemTom1 = new Tone.Part((time, event) => {
      
        tom1Sampler.triggerAttackRelease(event.note, event.dur, time);

      }, tom1);
      channels.push(channelItemTom1);

      tom2Sampler = instrumentsArr.find(({ name }) => name === "tom2");
      let channelItemTom2 = new Tone.Part((time, event) => {
        tom2Sampler.triggerAttackRelease(event.note, event.dur, time);

      }, tom2);
      channels.push(channelItemTom2);
      
      tom3Sampler = instrumentsArr.find(({ name }) => name === "tom3");
      let channelItemTom3 = new Tone.Part((time, event) => {
        tom3Sampler.triggerAttackRelease(event.note, event.dur, time);

      }, tom3);
      channels.push(channelItemTom3);

      chinaSampler = instrumentsArr.find(({ name }) => name === "china");
      let channelItemChina = new Tone.Part((time, event) => {
        chinaSampler.triggerAttackRelease(event.note, event.dur, time);

      }, china);
      channels.push(channelItemChina);


      kickSampler.volume.value = -5;
      snareSampler.volume.value =-5;
      rideSampler.volume.value =-5;
      crash1Sampler.volume.value =-5;
      crash2Sampler.volume.value =-5;
      hiHatSampler.volume.value =-5;
      hiHatOpenSampler.volume.value =-5;
      tom1Sampler.volume.value =-5;
      tom2Sampler.volume.value =-5;
      tom3Sampler.volume.value =-5;
      chinaSampler.volume.value =-5;
    }
  });
  return channels;
};

export const changeVolume = (value, channel) => {
  let current = volValues.findIndex((item) => item < value);

 switch(channel){
  case 'Vocals':
    vocalsSampler.volume.value = volThresholds[current];
  
    break;
  case 'Bass':
    bassSampler.volume.value = volThresholds[current];
    break;
  case 'Guitar':
    guitarSampler.volume.value = volThresholdsGuitar[current];
    console.log(guitarSampler.volume.value);
    break;
  case 'Drums':
    hiHatSampler.volume.value = volThresholds[current];
    snareSampler.volume.value = volThresholds[current];
    kickSampler.volume.value = volThresholds[current];
    break;
 }
 

};
