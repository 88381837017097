import React from "react";


const InputScreenWrapper = (props) =>{

    return  <div className={`input-screen-wrapper ${props.currentMode == props.forScreen ? "show" : ""}`}>
    <div className="input-screen d-flex align-items-center justify-content-center flex-column px-5">
      <h1 className="mb-3">{props.title}</h1>
      <div className="input-screen-field">
        {props.children}
      </div>
      <div className="input-explanation mt-3 py-3">
        {/* Every music piece has a tempo, which is expressed in beats per minute
        (bpm). Tempo is the speed or pace of a given piece. For example, a
        tempo of 60 beats per minute signifies one beat per second, while a
        tempo of 120 beats per minute is twice as rapid, signifying two beats
        every second. */}
        {props.text}
      </div>
      <button className="btn mt-3" onClick={props.changeInputScreenHandler}>
        SUBMIT
      </button>
    </div>
    </div>;
}

export default InputScreenWrapper;